<template>
  <div class="px-5">
    <player-form
      title="Crear jugador"
      ref="playerForm"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
      @deleteRepresentative="deleteRepresentative"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import PlayerForm from "./Form.vue"
export default {
  components: {
    PlayerForm
  },
  data() {
    return {
      loading: false,
      form: {
        photos: [{}]
      },
      visible: false
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    toggleVisibility(visible) {
      this.visible = visible
    },
    save() {
      let form = this.$refs.playerForm.form
      const representatives = this.$refs.playerForm.form.representatives
      const years_diff = this.$diffFrom(form.birthdate, "years")
      const isAdult = years_diff >= 18.0
      if (representatives.length === 0 && !isAdult)
        return this.$snotify.error(
          "Debes agregar al menos un representante",
          "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )

      let mother_dni_picture = null
      let father_dni_picture = null
      let other_dni_picture = null

      for (let representative of representatives) {
        if (parseInt(representative.type) === 1) {
          mother_dni_picture = representative.url_dni_picture
        } else if (parseInt(representative.type) === 2) {
          father_dni_picture = representative.url_dni_picture
        } else {
          other_dni_picture = representative.url_dni_picture
        }
        delete representative.url_dni_picture
        delete representative.key
      }
      this.loading = true

      this.$store
        .dispatch("global/create", {
          payload: {
            id_academy: form.id_academy,
            id_category: form.id_category,
            id_subcategory: form.id_subcategory,
            first_name: form.first_name,
            last_name: form.last_name,
            dni: form.dni,
            birthdate: form.birthdate,
            address: form.address,
            gender: form.gender,
            height: form.height,
            weight: form.weight,
            position: form.position,
            skillful_foot: form.skillful_foot,
            favorite_player: form.favorite_player,
            favorite_team: form.favorite_team,
            url_profile_picture: form.url_profile_picture,
            url_dni_picture: form.url_dni_picture,
            jersey_number: form.jersey_number,
            mother_dni_picture,
            father_dni_picture,
            other_dni_picture,
            representatives: JSON.stringify(representatives),
            active: !this.visible ? 0 : 1
          },
          route: "/player/save",
          module: "player"
        })
        .then((response) => {
          if ([200, 201].includes(response.code)) {
            if (form.photos.length > 0 && form.photos[0].url_photo) {
              this.savePlayerPhoto(response.data)
            } else {
              notify().then(({ notification }) => {
                notification("master", response.code, this.$snotify)
              })
              this.loading = false
              this.$router.push("/jugadores")
            }
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    deleteRepresentative(index) {
      if (this.$refs.playerForm.form.representatives.length === 1) {
        return this.$snotify.error(
          "No se pueden eliminar todos los representantes",
          "Error",
          {
            timeout: 5000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            titleMaxLength: 40,
            bodyMaxLength: 100,
            position: "rightBottom"
          }
        )
      }
      this.$refs.playerForm.form.representatives.splice(index, 1)
      return
    },
    savePlayerPhoto(user) {
      const photos = this.$refs.playerForm.form.photos
      this.$store
        .dispatch("global/create", {
          payload: {
            id_player: user.id,
            type_photo: photos[0].type_photo,
            url_photo: photos[0].url_photo
          },
          route: "/player_photo/save"
        })
        .then((response) => {
          if ([200, 201].includes(response.code)) {
            notify().then(({ notification }) => {
              notification("master", response.code, this.$snotify)
            })
            this.loading = false
            this.$router.push("/jugadores")
          }
        })
        .catch((error) => {
          console.log("player photo save error:", error)
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {}
}
</script>
